import React, { useState, useEffect } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/gatsby";

import { useLockBodyScroll } from "../../../hooks/useLookBodyScroll";
import LocationSvg from "../../images/icons/location.svg";
import useInstitutionImages from "../../hooks/useInstitutionImages";
import { postData } from "../../utils/apiClient";

interface Props {
    courseRefresherId: number;
    institutionImageId: string;
    institutionName: string;
    regionName: string;
    programName: string;
    campusName: string;
    onClose: () => void;
    requestSubmitted: () => void;
}

const CourseRefresherModal: React.FC<Props> = ({ courseRefresherId, institutionImageId, institutionName, regionName, campusName, programName, onClose, requestSubmitted }) => {
    useLockBodyScroll();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [token, setToken] = useState("");

    const institutionImages = useInstitutionImages();
    const institutionImage = institutionImages?.nodes?.find?.((item) => item.name == institutionImageId);
    const institutionImageData = institutionImage && getImage(institutionImage);

    const [form, setForm] = useState({
        name: "",
        email: "",
        phone: "",
        itaId: "",
        message: "",
    });

    const [isSubmitting, setIsSubmitting] = useState(false);

    const onChangeHandler = (e) => {
        setForm((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    const onSubmitHandler = async (event) => {
        event.preventDefault();

        if (!executeRecaptcha) {
            console.error("!executeRecaptcha");
            Sentry.captureMessage("executeRecaptcha is undefined");
            return;
        }

        const token = await executeRecaptcha();
        const values = { ...form, id: courseRefresherId, institutionId: institutionImageId, token };

        if (!values.name) {
            toast.error("Please enter a name");
            return;
        }

        if (!values.email) {
            toast.error("Please enter an email");
            return;
        }

        if (!values.message) {
            toast.error("Please enter a question");
            return;
        }

        setIsSubmitting(true);

        const status = await postData(`trades-training-bc/course-refresher`, values);
        if (status && status === 200) toast.success("Course refresher request submitted") && requestSubmitted(`${programName}-${institutionName}`);
        onClose();

        setIsSubmitting(false);
    };

    useEffect(() => {
        const close = (e) => {
            if (e.key === "Escape") {
                onClose();
            }
        };
        document.addEventListener("keydown", close);
        return () => document.removeEventListener("keydown", close);
    }, []);

    return (
        <div className="font-mono fixed top-0 left-0 z-10 z-50 flex h-full w-full justify-center bg-darkGray bg-opacity-75 sm:items-center">
            <div className="relative z-10 m-5 max-w-[800px] overflow-y-scroll bg-white sm:overflow-hidden">
                <header className="flex justify-between bg-blue p-4 text-white">
                    <p>Request information from {institutionName}</p>
                    <button onClick={onClose} className="h-6 rounded-sm bg-white px-2 font-bold text-blue">
                        x
                    </button>
                </header>
                <form className="mx-6 my-4 border-b  border-gray pb-5">
                    <div className="flex flex-col gap-8 sm:flex-row">
                        <div className="mt-3 flex-1">
                            <h3 className="text-h4 capitalize">My Information</h3>
                            <div className="mt-5 flex flex-col font-roboto text-h3 text-[13px]">
                                <label>* Name:</label>
                                <input onChange={onChangeHandler} className="mt-1 rounded-sm border border-gray px-2 py-1" name="name" type="text" maxLength={50} />
                            </div>
                            <div className="mt-3 flex flex-col font-roboto text-h3 text-[13px]">
                                <label>* Email:</label>
                                <input onChange={onChangeHandler} className="mt-1 rounded-sm border border-gray px-2 py-1" name="email" type="email" maxLength={50} />
                            </div>
                            <div className="mt-3 flex flex-col font-roboto text-h3 text-[13px]">
                                <label>Phone:</label>
                                <input onChange={onChangeHandler} className="mt-1 rounded-sm border border-gray px-2 py-1" name="phone" type="phone" maxLength={25} />
                            </div>
                            <div className="mt-3 flex flex-col font-roboto text-h3 text-[13px]">
                                <label>Trades BC (ITA) ID:</label>
                                <input onChange={onChangeHandler} className="mt-1 rounded-sm border border-gray px-2 py-1" name="itaId" type="text" maxLength={50} />
                            </div>
                        </div>
                        <div className="flex-1">
                            <div className="flex h-28 justify-center">
                                <GatsbyImage className="block h-full" image={institutionImageData} alt={"logo"} loading={"eager"} />
                            </div>
                            <h4 className="mt-5 text-h3 font-bold capitalize">Course :</h4>
                            <p className="text-h3">{programName}</p>
                            <h4 className="mt-5 text-h3 font-bold capitalize">Campus :</h4>
                            <p className="flex items-center gap-1 text-h3">
                                <LocationSvg className="w-6" />
                                {campusName}
                            </p>
                        </div>
                    </div>
                    <div className="mt-5 flex flex-col font-roboto">
                        <label className="break-words text-h3">
                            * Please type your question(s) below, this information will be e-mailed to <strong>{institutionName}</strong>
                        </label>
                        <textarea onChange={onChangeHandler} name="message" maxLength={255} className="mt-3 h-16 rounded-sm border border-gray p-2"></textarea>
                        {form.message.length < 255 ? <p className="text-h3">{form.message.length}/255 characters</p> : <p className="text-h3 text-red">Character limit reached</p>}
                    </div>
                </form>
                <footer className="mx-6 my-4 flex justify-evenly font-roboto">
                    <button disabled={isSubmitting} onClick={onSubmitHandler} className="bg-blue px-4 py-2 text-[18px] uppercase text-white">
                        Submit
                    </button>
                    <button onClick={onClose} className="bg-gray px-6 py-2 text-[18px] text-white">
                        Close
                    </button>
                </footer>
            </div>
        </div>
    );
};

export default CourseRefresherModal;
