import React, { useEffect, useState, useRef } from "react";
import { graphql, Link } from "gatsby";
import Seo from "../components/Seo";
import PageHeader from "../components/PageHeader";
import CourseRefresherTable from "../components/tables/CourseRefresher";
import CourseRefresherTableMobile from "../components/tables/CourseRefresherMobile";
import CourseRefresherFiltersSearch from "../components/CourseRefresherFiltersSearch";
import { matchSorter } from "match-sorter";
import { COURSE_REFRESHER_ALL_INSTITUTION_VALUE } from "../constants/constants";

const CourseRefresher = (props) => {
    const { data: { allCourseRefresher, allInstitution } } = props;

    const [courseRefreshers, setCourseRefreshers] = useState(allCourseRefresher.nodes);
    const [search, setSearch] = useState(null);

    const regionRef = useRef("");
    const institutionRef = useRef("");
    const queryParametersRef = useRef(new URLSearchParams());

    useEffect(() => {
        // Extract URL parameters
        const queryParameters = new URLSearchParams(document.location.search);
        const region = queryParameters.get("region")?.toLowerCase() || "";
        const institution = queryParameters.get("inst")?.toLowerCase() || "";

        regionRef.current = region;
        institutionRef.current = institution;
        queryParametersRef.current = queryParameters;

        // Filter refresher results based on the validated region and institution parameters
        const filteredCourseRefreshers = allCourseRefresher.nodes.filter((r) => {
            const matchesRegion = region ? r.region.toLowerCase() === region : true;
            const matchesInstitution = institution ? (institution === COURSE_REFRESHER_ALL_INSTITUTION_VALUE.toLowerCase() || r.institution.toLowerCase() === institution) : true;
            return matchesRegion && matchesInstitution;
        });

        setCourseRefreshers(filteredCourseRefreshers);

    }, [typeof window !== "undefined" ? window.location.search : null]);

    const handleChangeSearch = (e) => {
        setSearch(e.target.value || null);
    };

    // Kept for reference
    // const filteredCourseRefreshers = search ? matchSorter(courseRefreshers, search, { keys: ["name"] }) : courseRefreshers;
    const filteredCourseRefreshers = (search && typeof search === "string" && search.trim()) ?
        courseRefreshers.filter(cr => {
            const tokens = search.trim().split(/\s+/); // Split by any whitespace
            return tokens.every(token => cr.name.toLowerCase().includes(token.toLowerCase()));
        }) : courseRefreshers;


    const tableHeaders = [{ title: "Institution" }, { title: "Program Name" }, { title: "Region" }, { title: "Campus" }, { title: `Contact Institution` }, { title: "Visit Program Page" }];
    const mobileTableHeaders = [{ title: "Institution" }, { title: "Program Name" }, { title: "Region" }, { title: "Campus" }, { title: `Contact` }, { title: "Program Page" }];

    const pageTitle = queryParametersRef.current.size > 0 ? "Refresher Programs" : "All Available Refresher Programs";

    return (
        <>
            <Seo title={pageTitle} />
            <PageHeader title={pageTitle} breadcrumbs={[{ label: pageTitle, url: "/CourseRefresher" }]} />
            <CourseRefresherFiltersSearch region={regionRef.current} institution={institutionRef.current}
                                          onChange={handleChangeSearch} />
            <div>
                <div className="hidden sm:block">
                    <CourseRefresherTable refresherCourses={filteredCourseRefreshers} headers={tableHeaders}
                                          allInstitution={allInstitution} />
                </div>
                <div className="block sm:hidden">
                    <CourseRefresherTableMobile refresherCourses={filteredCourseRefreshers} headers={mobileTableHeaders}
                                                allInstitution={allInstitution} />
                </div>
            </div>
        </>
    );
};

export default CourseRefresher;

export const pageQuery = graphql`
    query CourseRefresherQuery {
        allCourseRefresher(sort: { fields: [institution, name, region], order: [ASC, ASC, ASC] }) {
            nodes {
                id
                databaseId
                institution
                name
                region
                campus
                url
            }
        }
        allInstitution {
            nodes {
                databaseId
                name
                shortName
            }
        }
    }
`;
