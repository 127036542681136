import { useStaticQuery, graphql } from 'gatsby';

const useInstitutionImages = () => {
    const { institutionImages } = useStaticQuery(graphql`
        query RequestInformationQuery {
            institutionImages: allFile(filter: { relativeDirectory: { eq: "logos" } }) {
                nodes {
                    childImageSharp {
                        gatsbyImageData
                    }
                    name
                }
            }
        }
    `);

    return institutionImages;
};

export default useInstitutionImages;
