/* This example requires Tailwind CSS v2.0+ */
import React, { useState } from 'react';
import BlueMail from '../../images/icons/BlueMail.svg';
import Arrow from '../../images/icons/Arrow.svg';
import CourseRefresherModal from '../modals/CourseRefresherModal';

export default function CourseRefresherTableMobile(props) {
    const { table, uri, headers, refresherCourses, allInstitution } = props;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCourseRefresher, setSelectedCourseRefresher] = useState(null);

    const handleCourseRefresherSelect = (refresherCourse) => {
        setSelectedCourseRefresher(refresherCourse);
        setIsModalOpen(true);
    };

    const getInstitutionImage = (institutionName) => {
        const institution = allInstitution.nodes.find((el) => el.shortName === institutionName);
        return institution?.databaseId;
    };

    const handleModelClose = () => {
        setIsModalOpen(false);
        setSelectedCourseRefresher(null);
    };

    const requestSubmitted = (program) => {
        logAnalyticsEvent(`Contact institution form: ${program}`)
    };

    const logAnalyticsEvent = (userClick) => {
        if (typeof window !== 'undefined') {
            //custom gtag event only works on production and errors in develop, so bypass develop errors with try/catch
            try {
                window.gtag("event", "course_refresher_action", {
                    "user_click": userClick
                });
            } catch (e) {}
        }
    };

    return (
        <>
            {isModalOpen && (
                <CourseRefresherModal
                    courseRefresherId={selectedCourseRefresher.databaseId}
                    institutionImageId={getInstitutionImage(selectedCourseRefresher.institution)}
                    institutionName={selectedCourseRefresher.institution}
                    regionName={selectedCourseRefresher.region}
                    campusName={selectedCourseRefresher.campus}
                    programName={selectedCourseRefresher.name}
                    onClose={handleModelClose}
                    requestSubmitted={requestSubmitted}
                />
            )}
            <div className="flex] flex-col px-[20px]">
                <div className="-my-2 mb-[32px]">
                    <div className="py-2 align-middle">
                        <div className="overflow-x-auto rounded-lg shadow-tableShadow ">
                            <table className="block md:hidden overflow-y-hidden">
                                {refresherCourses.length < 1 &&
                                    <tr className="flex flex-1 flex-col bg-white">
                                        <td className="text-sm flex h-[47px] items-center px-3 py-4 font-robotoCondensed text-pTable text-gray900">
                                            Sorry, we weren't able to find a match
                                        </td>
                                    </tr>
                                }
                                {refresherCourses.map((course, index) => (
                                    <tbody key={index} className={index % 2 === 0 ? 'flex flex-row bg-blue' : 'flex flex-row bg-darkBlue'}>
                                        <tr>
                                            {headers &&
                                                headers.map((header, index) => {
                                                    return (
                                                        index >= 0 && (
                                                            <th
                                                                key={index}
                                                                scope="col"
                                                                className={
                                                                    uri === '/tradeprograms' && index % 2 === 0
                                                                        ? 'flex h-[47px] flex-col px-2 py-3 uppercase tracking-wider text-white'
                                                                        : 'flex h-[47px] flex-col px-2 py-3 text-right uppercase tracking-wider text-white '
                                                                }
                                                            >
                                                                <h3 className="my-auto">{header.title}</h3>
                                                            </th>
                                                        )
                                                    );
                                                })}
                                        </tr>

                                        <tr key={index} className={index % 2 === 0 ? 'flex flex-1 flex-col bg-white' : 'flex flex-1 flex-col bg-logoBG'}>
                                            <td className="text-sm flex h-[47px] items-center px-3 py-4 font-robotoCondensed text-pTable text-gray900">{course.institution}</td>

                                            <td className="text-sm flex h-[47px] items-center px-3 py-4 font-robotoCondensed text-pTable text-gray900">{course.name}</td>

                                            <td className="text-sm flex h-[47px] items-center px-3 py-4 font-robotoCondensed text-pTable text-gray900">{course.region}</td>

                                            <td className="text-sm flex h-[47px] items-center px-3 py-4 font-robotoCondensed text-pTable text-gray900">{course.campus}</td>

                                            <td className="text-sm flex h-[47px] items-center px-3 py-4 font-robotoCondensed text-pTable text-gray900">
                                                <div className="w-[88px] rounded-[4px] py-[5px] text-pTable text-gray700">
                                                    <button className="flex w-fit flex-row items-center justify-start rounded-[5px] border-2 border-gray px-[9px] py-[8px]" 
                                                            onClick={() => handleCourseRefresherSelect(course)}>
                                                        <BlueMail className="w-[22px] h-[22px]" />
                                                    </button>
                                                </div>
                                            </td>

                                            <td className="text-sm flex h-[47px] items-center px-3 py-4 font-robotoCondensed text-pTable text-gray900">
                                                <div className="w-[88px] rounded-[4px] py-[5px] text-pTable text-gray700">
                                                    <a href={course.url} target={'_blank'} className="flex w-fit flex-row items-center justify-start rounded-[5px] border-2 border-gray px-[9px] py-[8px]"
                                                       onClick={() => logAnalyticsEvent(`Visit program page: ${course.name}-${course.institution}`)}>
                                                        <Arrow />
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                ))}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
