import React, { useState } from "react";
import BlueMail from "../../images/icons/BlueMail.svg";
import Arrow from "../../images/icons/ArrowSmall.svg";
import CourseRefresherModal from "../modals/CourseRefresherModal";

export default function CourseRefresherTable(props) {
    const { table, uri, headers, refresherCourses, allInstitution } = props;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCourseRefresher, setSelectedCourseRefresher] = useState(null);

    const handleCourseRefresherSelect = (refresherCourse) => {
        setSelectedCourseRefresher(refresherCourse);
        setIsModalOpen(true);
    };

    const getInstitutionImage = (institutionName) => {
        const institution = allInstitution.nodes.find((el) => el.shortName === institutionName);
        return institution?.databaseId;
    };

    const handleModelClose = () => {
        setIsModalOpen(false);
        setSelectedCourseRefresher(null);
    };

    const requestSubmitted = (program) => {
        logAnalyticsEvent(`Contact institution form: ${program}`);
    };

    const logAnalyticsEvent = (userClick) => {
        if (typeof window !== "undefined") {
            //custom gtag event only works on production and errors in develop, so bypass develop errors with try/catch
            try {
                window.gtag("event", "course_refresher_action", { "user_click": userClick });
            } catch (e) {
            }
        }
    };

    return (
        <>
            {isModalOpen && (
                <CourseRefresherModal
                    courseRefresherId={selectedCourseRefresher.databaseId}
                    institutionImageId={getInstitutionImage(selectedCourseRefresher.institution)}
                    institutionName={selectedCourseRefresher.institution}
                    regionName={selectedCourseRefresher.region}
                    campusName={selectedCourseRefresher.campus}
                    programName={selectedCourseRefresher.name}
                    onClose={handleModelClose}
                    requestSubmitted={requestSubmitted}
                />
            )}
            <div className="flex w-full flex-col px-[15px]">
                <div className="flex justify-center py-2 align-middle">
                    <div className="overflow-clip shadow-tableShadow sm:rounded-lg">
                        <table className="lg:min-w-[1300px]">
                            <thead className="sticky top-0 bg-blue">
                            <tr>
                                {headers &&
                                    headers.map((header, index) => {
                                        return (
                                            index >= 0 && (
                                                <th key={index} scope="col"
                                                    className={"px-[17px] py-3 uppercase tracking-wider text-white  "}>
                                                    <h3>{header.title}</h3>
                                                </th>
                                            )
                                        );
                                    })}
                            </tr>
                            </thead>
                            <tbody>
                            {refresherCourses.length < 1 &&
                                <tr className="bg-white">
                                    <td className="text-sm px-[17px] py-3 font-robotoCondensed text-pTable text-gray900">
                                        Sorry, we weren't able to find a match
                                    </td>
                                </tr>
                            }
                            {refresherCourses.map((course, index) => (
                                <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-logoBG"}>
                                    <td className="text-sm px-[17px] py-3 font-robotoCondensed  text-pTable text-gray900">{course.institution}</td>
                                    <td className="text-sm px-[17px] py-3 font-robotoCondensed  text-pTable text-gray900">{course.name}</td>
                                    <td className="text-sm px-[17px] py-3 font-robotoCondensed  text-pTable text-gray900">{course.region}</td>
                                    <td className="text-sm px-[17px] py-3 font-robotoCondensed  text-pTable text-gray900">{course.campus}</td>

                                    <td className="text-sm px-[17px] py-3 font-robotoCondensed  text-pTable text-gray900">
                                        <div
                                            className="mx-auto w-[88px] rounded-[4px] py-[5px] text-pTable text-gray700">
                                            <button
                                                className="flex w-fit flex-row items-center justify-start rounded-[5px] border-2 border-gray px-[9px] py-[8px] hover:border-lightBlue"
                                                onClick={() => handleCourseRefresherSelect(course)}>
                                                <BlueMail className="w-[16px] h-[16px]" />
                                            </button>
                                        </div>
                                    </td>

                                    <td className="text-sm px-[17px] py-3 font-robotoCondensed  text-pTable text-gray900">
                                        <div
                                            className="mx-auto w-[88px] rounded-[4px] py-[5px] text-pTable text-gray700">
                                            <a href={course.url} target={"_blank"}
                                               className="flex w-fit flex-row items-center justify-start rounded-[5px] border-2 border-gray px-[9px] py-[8px] hover:border-green"
                                               onClick={() => logAnalyticsEvent(`Visit program page: ${course.name}-${course.institution}`)}>
                                                <Arrow />
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}
