import { Link } from "gatsby";
import Edges from "./Edges";
import React from "react";
import SearchIcon from "../images/icons/SearchIcon.svg";

const CourseRefresherFiltersSearch = (props) => {
    const { region, institution, onChange } = props;

    return (
        <Edges size="md">
            <div className="mb-4 flex flex-col justify-between px-[15px] md:flex-row md:px-[0px]">
                <div className="flex flex-col md:flex-row md:items-center ">
                    <div className="relative">
                        <input
                            className="my-[5px] w-full rounded-[6px] border-2 border-gray py-[5px] px-[11px] pl-8 pr-2 font-robotoCondensed text-gray900 outline-offset-0 md:my-0 md:w-[275px]"
                            type="search"
                            placeholder="Search Refreshers"
                            onChange={onChange}
                            id="searchInput"
                        />
                        <div className="absolute top-[16px] left-[10px] md:top-[12px]">
                            <SearchIcon className="h-4 w-4" />
                        </div>
                    </div>
                </div>
                {(region || institution) && (
                    <div className="mb-3 mt-[6px] flex flex-wrap space-x-2 space-y-1 md:mb-[6px] md:space-y-0">
                        <div
                            className="pt-[10px] font-robotoCondensed text-pTable uppercase italic md:pt-[6px]">Filters:
                        </div>

                        {region && (
                            <div
                                className="cursor-default rounded-lg bg-gray500 py-1.5 px-3 font-robotoCondensed text-pTable uppercase text-white">Region: {region}
                            </div>
                        )}

                        {institution && (
                            <div
                                className="cursor-default rounded-lg bg-gray500 py-1.5 px-3 font-robotoCondensed text-pTable uppercase text-white">Institution: {institution}
                            </div>
                        )}

                        <Link to={"/CourseRefresher"}
                              className="rounded-lg bg-red py-1.5 px-3 font-robotoCondensed text-pTable uppercase text-white">
                            Clear filters
                        </Link>
                    </div>
                )}
            </div>
        </Edges>
    );
};

export default CourseRefresherFiltersSearch;
